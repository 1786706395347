import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/cloud-monitoring/case-study",
    label: "CASE STUDY",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="CASE STUDY" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--for-it-ops-csy-fr">
      <section className="section-bio pt-5">
        <div className="container">
          <div className="row">
            <div className="pcsy">
              <div className="section-col-cs">
                <h4>CASE STUDY</h4>
                <hr />
              </div>
              <div className="col-sm-7">
                <h1>Fast Remediation</h1>
                <h3>VigilantMNDR</h3>
              </div>
              <div className="col-sm-4">
                <p className="pt-5">
                  A large manufacturing company with 6 sizable industrial
                  locations was responsible for manufacturing, packaging, and
                  transporting significant materials of which many massive
                  construction operations relied upon every day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-blocks-6">
      <div className="container">
        <div className="csy pt-5 col-sm-10">
          <p>
            They were in constant risk of falling behind. Every single day,
            around a certain timeframe, their bandwidth would slow to a crawl at
            all six locations. The problem had persisted for some time and with
            all the IT Ops turnover, they felt like they were reinventing the
            wheel. This systemic vulnerability was putting their reputation and
            future in real jeopardy.
          </p>
          <p>
            Vigilant was brought on-board and we plugged in our Network
            Detection Sensors. We did a full diagnostic of all systems,
            technologies, and software. We immediately detected a major file
            transfer, routing all traffic from remote sites into this central
            organization hub and out to the internet. Every day! We discovered
            it was an old version of Symantec Backup that wasn’t really
            interacting with anything else, but it was a major drag on bandwidth
            and consequently, production.
          </p>
          <p>
            What they thought impossible to find, we identified in less than a
            day. They didn’t even have the logins anymore, but we identified the
            IPS, helped them track it down and turn it off. Just like that,
            their “impossible” problem was solved.
          </p>
          <p>
            No one knew it was there. It was a legacy system no one had
            interacted with for years (because it was installed by IT staff
            several generations before). An example of the Legacy-Communication
            Gap. They may have never found it if it hadn’t been for Vigilant’s
            omnidirectional visibility throughout their systems (new and
            legacy).
          </p>
          <p>
            We determined that this particular company, at this particular time,
            would be better protected by a different endpoint tool. AND since
            this client was with VIGILANT and the contract was with our service,
            NOT the tools, we were able to switch them over, and quickly
            integrate their entire network into the new tool.
          </p>
          <p>
            This wasn’t a security breach. This wasn’t a cyberattack. It was a
            hidden and forgotten system that was destroying production
            efficiency. But when you partner with the best visibility team
            across any industry, there are many ways beyond security where we
            can help.
          </p>
        </div>
      </div>
    </section>

    <div className="text-center pb-5">
      <Link to="/contact/" className="nav-section--cta-btn">
        <div className="d-flex align-items-center">
          <span
            style={{ marginLeft: "25px" }}
            className="nav-section--cta-title pr-5"
          >
            schedule a call
          </span>
          <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
        </div>
      </Link>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
